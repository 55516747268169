<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="addParticipant"
  >
    <v-container>
      <v-row>
        <v-col cols="10">
          <v-text-field
            v-model="participant.name"
            :counter="40"
            label="Nome"
            required
            :rules="nameRules"
            autocomplete="off"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field
            type="number"
            v-model="participant.tickets"
            :rules="ticketsRules"
            label="Tickets"
            min="1"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn color="primary" title="Adicionar" @click="addParticipant">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      participant: {
        name: "",
        tickets: 1
      },
      nameRules: [
        v => !!v || "Requerido",
        v =>
          this.participants.find(({ name }) => name === v) == undefined ||
          "Nome do participante deve ser único."
      ],
      ticketsRules: [
        v => !!v || "Requerido",
        v => parseInt(v, 10) > 0 || "Maior que zero"
      ]
    };
  },
  computed: {
    participants() {
      return this.$store.state.participants.participants;
    },
    formErros() {
      return Object.entries(this.$store.state.participants.form.errors)
        .map(entry => entry[1])
        .filter(value => !!value);
    }
  },
  watch: {
    participant: {
      immediate: false,
      deep: true,
      handler(newValue) {
        Object.entries(newValue).forEach(([key, value]) => {
          this.$store.dispatch("editForm", { key, value });
        });
      }
    }
  },
  methods: {
    addParticipant() {
      this.validate();
      if (this.valid) {
        this.$store.dispatch("addParticipant");
        this.resetForm();
        this.resetValidation();
        console.log("Adding participant");
      }
    },
    resetForm() {
      this.participant.name = "";
      this.participant.tickets = 1;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>
