<template>
  <div class="people">
    <h2 class="text-h2 text-center pa-10">Adicionar Participantes</h2>

    <v-divider class="my-2" />
    <v-container>
      <AddParticipantFrom />

      <v-divider class="my-8" />

      <template v-if="participants.length">
        <ParticipantsList :items="participants" />

        <v-divider class="my-4" />

        <v-btn block outlined rounded x-large color="primary" to="/draw">
          Iniciar Sorteio
        </v-btn>
      </template>
    </v-container>
  </div>
</template>
<script>
import AddParticipantFrom from "@/components/AddParticipantFrom.vue";
import ParticipantsList from "@/components/ParticipantsList.vue";

export default {
  name: "Participants",
  components: {
    ParticipantsList,
    AddParticipantFrom
  },
  computed: {
    participants() {
      return this.$store.state.participants.participants || [];
    }
  },
  created() {
    this.$store.dispatch("fetchParticipants");
  }
};
</script>
