<template>
  <v-card class="mx-auto" elevation="4">
    <!-- <v-card-title class="text-h5 font-weight-light">Participantes</v-card-title> -->
    <v-toolbar>
      <v-toolbar-title>Participantes</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined :loading="isLoadingUsers" @click="fetchParticipants">
        Atualizar
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-toolbar>

    <template v-if="items.length === 0">
      <p class="text-caption font-weight-thin text-center">
        Nenhum participante
      </p>
    </template>
    <v-simple-table v-else fixed-header height="300px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Tickets
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.tickets }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="removeParticipant(item.id)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="red">mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>Remover</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoadingUsers: false
    };
  },
  methods: {
    removeParticipant(id) {
      const yes = window.confirm(`Tem certeza que deseja remover ${id}?`);

      if (yes) this.$store.dispatch("removeParticipant", id);
    },
    async fetchParticipants() {
      this.isLoadingUsers = true;
      await this.$store.dispatch("fetchParticipants");
      this.isLoadingUsers = false;
    }
  }
};
</script>
