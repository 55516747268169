<template>
  <div class="draw">
    <h2 class="text-h2 text-center pa-10">Quem vai ganhar? ❤️</h2>
    <v-divider />
    <v-container v-if="participants.length">
      <v-row justify="center" class="my-4">
        <v-btn
          :loading="loading"
          color="primary"
          block
          rounded
          x-large
          @click="draw"
        >
          Sortear
        </v-btn>
      </v-row>

      <div justify="center" class="my-8" v-if="winners.length">
        <v-alert
          v-if="currentWinner"
          class="font-weight-bold text-center text-uppercase"
          prominent
          color="green"
          elevation="8"
          type="success"
          :icon="false"
        >
          <strong>{{ currentWinner.name }}</strong>
        </v-alert>

        <v-divider class="my-8" />

        <h4 class="text-h4 text-center font-weight-light">
          Pessoas Ganhadoras
        </h4>
        <v-list justify="center">
          <v-list-item v-for="winner in winners" :key="winner.id">
            <v-list-item-icon>
              <v-icon color="primary">mdi-star-face</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ winner.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-container>
    <v-container v-else>
      <v-btn block outlined rounded x-large color="primary" to="/participants">
        Adicione Participantes
      </v-btn>
    </v-container>
  </div>
</template>

<script>
const DRAW_TIMING = 5000; // ms
export default {
  name: "Draw",
  data() {
    return {
      loading: false
    };
  },
  computed: {
    winners() {
      return this.$store.state.drawing.winners;
    },
    participants() {
      return this.$store.state.participants.participants || [];
    },
    currentWinner() {
      return this.$store.state.drawing.currentWinner;
    }
  },
  methods: {
    draw() {
      this.loading = true;
      setTimeout(() => {
        this.$store.dispatch("drawWinner").finally(() => {
          this.loading = false;
        });
      }, DRAW_TIMING);
    }
  }
};
</script>
